import { Flex, Text } from '@tyb-u/tyb-ui-components';
import CollectiblePreview from 'src/components/CollectiblePreview';
import Loading from 'src/components/Loading';
import { IBrandEcommRedemptionData } from 'src/interface/IBrandEcommRedemptionData';
import { ICollectible } from 'src/types';

interface CollectibleAmountProps {
  collectible: ICollectible;
  ecommBrandRedemption?: IBrandEcommRedemptionData;
  isLoading?: boolean;
  children: React.ReactElement;
}

const CollectibleAmount: React.FC<CollectibleAmountProps> = ({
  collectible,
  ecommBrandRedemption,
  isLoading,
  children,
}) => (
  <Flex flex={1} flexDirection="column" alignItems="center" justifyContent="space-between" __css={{ gap: '20px' }}>
    <Flex flex={1} flexDirection="column" alignItems="center" justifyContent="center" __css={{ gap: '20px' }}>
      <CollectiblePreview collectible={collectible} width="170px" height="170px" borderRadius="16px" />
      {!!ecommBrandRedemption && (
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundColor="neutrals-50"
          borderRadius="25px"
          p="6px 12px"
          __css={{ gap: '5px' }}
        >
          <CollectiblePreview
            collectible={collectible}
            width="16px"
            height="16px"
            borderRadius="16px"
            style={{ width: 'unset !important' }}
          />
          <Text variant="text7-600" color="neutrals-700">
            {`${ecommBrandRedemption.gate.quantity} ${collectible.symbol} = ${ecommBrandRedemption.effect.value} USD`.toUpperCase()}
          </Text>
        </Flex>
      )}

      <Text textAlign="center" variant="text3-400">
        You have <span style={{ fontWeight: 'bold' }}>{collectible.balance}</span> {collectible.metadata.name}{' '}
        {collectible.balance > 1 ? 'coins' : 'coin'}
      </Text>
    </Flex>
    {isLoading ? <Loading /> : children}
  </Flex>
);

export default CollectibleAmount;
